<template>
  <section>
    <!--工具条-->
    <el-col :span="24" class="toolbar" style="padding-bottom: 0px">
      <el-form label-width="80px">
        <el-col :span="12">
          <el-form-item label="教学类型">
            <el-radio-group v-model="queryTeachType" size="small">
              <el-radio-button label="3">FEED</el-radio-button>
              <el-radio-button label="5">recite背诵</el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="教学名称">
            <el-input v-model="queryTeachName" placeholder="TODO"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" @click="onQuery">查询</el-button>
          <el-button @click="onQuery">刷新</el-button>
        </el-col>
      </el-form>
    </el-col>

    <!--列表-->
    <el-table :data="teachList" highlight-current-row style="width: 100%">
      <el-table-column prop="teachId" label="id"></el-table-column>
      <el-table-column prop="teachName" label="名称"></el-table-column>
      <el-table-column prop="createTime" :formatter="formatTime" label="创建时间"></el-table-column>
      <el-table-column label="操作" width="220">
        <template slot-scope="scope">
          <el-button size="small" @click="onEditTeach(scope.$index, scope.row)">修改</el-button>
          <el-button size="small" @click="onCopy(scope.$index, scope.row)">复制</el-button>
          <el-button type="danger" size="small" @click="onDeleteTeach(scope.$index, scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-col :span="24" class="toolbar">
      <el-pagination
        background
        layout="prev, pager, next"
        :current-page="currentPage"
        :page-count="totalPage"
        @current-change="onChangePage"
      ></el-pagination>
    </el-col>
  </section>
</template>

<script>
import { xteachApi } from "@/api/xteachApi";
import { epochToDateString } from "@/util/TimeUtil";
import { TeachTypeEnum } from "@/util/EnumUtil";
import { alertMessage } from "@/util/alertMessageUtil.js";
export default {
  data() {
    return {
      queryTeachName: "",
      queryTeachType: 3, //feed
      teachList: [],
      currentPage: 1,
      totalPage: 10,
    };
  },
  mounted() {
    this.onQuery();
  },
  methods: {
    formatTime: function(row, column) {
      return epochToDateString(row[column.property]);
    },
    onChangePage(val) {
      this.currentPage = val;
      this.onQuery();
    },
    //获取列表
    onQuery() {
      let start = (this.currentPage - 1) * 20;
      let len = 20;
      if (this.queryTeachName) {
        xteachApi
          .searchTeach(this.queryTeachType, this.queryTeachName, start, len)
          .then((ret) => {
            let { code, data, totalPage } = ret;
            if (code == 0) {
              this.teachList = data;
              this.totalPage = totalPage;
            }
          })
          .catch((err) => {
            console.log(err);
            this.$message.error("搜索出错");
          });
      } else {
        xteachApi
          .listTeach(parseInt(this.queryTeachType), start, len)
          .then((ret) => {
            if (ret.code === 0) {
              this.teachList = ret.data;
              this.totalPage = ret.totalPage;
            }
          })
          .catch((err) => {
            console.log(err);
            this.$message.error("获取列表出错");
          });
      }
    },
    confirmDelete(teachId, index) {
      return new Promise(() => {
        xteachApi.deleteTeach(teachId).then((res) => {
          if (res.code === 0) {
            alertMessage("删除成功", "success");
            this.teachList.splice(index, 1);
          } else {
            alertMessage(res.msg, "warning");
          }
        });
      });
    },

    async onDeleteTeach(index, row) {
      let self = this;
      let msg = "确定删除该项吗？";
      this.$confirm(msg, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await self.confirmDelete(row.teachId, index);
        })
        .catch(() => {});
    },
    onEditTeach(index, row) {
      if (row.teachType === TeachTypeEnum.FEED || row.teachType == TeachTypeEnum.RECITE) {
        //Feed教学
        this.$router.push({
          name: "feed-edit",
          params: { id: row.teachId },
        });
      }
    },
    onCopy(index, row) {
      this.$confirm("确定复制教学资产嘛, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          xteachApi.copyTeach(row.teachId).then((ret) => {
            console.log("copy", ret);
            if (ret.code == 0 && ret.data) {
              this.$message({
                type: "success",
                message: "复制成功!",
              });
              this.onQuery();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消复制",
          });
        });
    },
  },
  watch: {
    queryTeachType: function(newVal, oldVal) {
      console.log(newVal, oldVal);
      this.currentPage = 1;
      this.onQuery();
    },
    queryTeachName: function(newVal, oldVal) {
      this.currentPage = 1;
    },
  },
};
</script>

<style scoped></style>
